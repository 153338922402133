import { React } from 'react'
import { useNavigate } from 'react-router-dom'

import { BILLING_ICONS, errorIcon } from '../../../constants/icons'
import Modal from '../Modal/Modal'
import Button from '../Button'

const ResponseModal = ({
  handleClose,
  isModalOpen,
  error,
  route,
  successMessage,
  errorMessage
}) => {
  const navigate = useNavigate()

  return (
    <Modal handleClose={handleClose} show={isModalOpen}>
      <div className="text-center py-4 font-normal text-base text-black">
        {error ? (
          <>
            <img src={errorIcon} className="w-16 h-16 mx-auto" alt="error" />
            <div className="py-4 px-1">
              Ha ocurrido un error. Contacte a soporte PinFlag
              {errorMessage ? ' con el siguiente error' : '.'}
            </div>
            <div className="text-xs mx-auto overflow-auto max-h-1/4-screen lg:max-w-1/3-screen">
              {errorMessage}
            </div>
          </>
        ) : (
          <>
            <img src={BILLING_ICONS.paid} className="w-16 h-16 mx-auto" alt="success" />
            <div className="py-4 px-1">{successMessage}</div>

            <div className="flex justify-end">
              {route && (
                <Button color="bg-normal-pinflag" type="button" onClick={() => navigate(route)}>
                  Confirmar
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default ResponseModal

import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { AuthContext } from './contexts/Store'
import GlobalStyles from './GlobalStyles.style'
import Sidebar from './components/Sidebar/SideBar'
import Warehouses from './pages/Warehouses/WarehouseList/Warehouses.style'
import WarehousePage from './pages/Warehouses/WarehouseDetail/WarehousePage.style'
import Dashboard from './pages/Dashboard'
import NotFound from './pages/NotFound'

import RecoverPass from './components/RecoverPass/RecoverPass/RecoverPass.style'
import RestorePass from './components/RecoverPass/RestorePass/RestorePass.style'
import Billing from './components/Billing/Billing.style'
import NotificationCenter from './components/NotificationCenter/NotificationCenter/NotificationCenter'
import ROUTES from './constants/routes'
import CrossApproval from './components/CrossApproval/CrossApproval.style'
import SalesOrderCreation from './pages/Sales/SalesOrderCreation'
import Pricing from './pages/Shippings/Pricing'

import Login from './pages/Login'
import UserProfile from './pages/UserProfile'
import Sales from './pages/Sales/Sales'
import Manifest from './pages/Manifest/Manifest'
import Personalization from './pages/Settings/Personalization'
import PickUpApp from './pages/Settings/PickUpApp'
import Tickets from './pages/Tickets/Tickets'
import Rules from './pages/Rules/Rules'
import Interceptors from './Interceptors'
import ShowTicket from './pages/Tickets/ShowTicket'
import NewTicket from './pages/Tickets/NewTicket'
import Rule from './pages/Rules/EditRule'
import NewRule from './pages/Rules/CreateRule'
import FAQs from './pages/FAQs/FAQs'
import './App.css'
import RouterGuards from './components/RouterGuards'
import PackingList from './pages/Sales/PackingList'
import PickingList from './pages/Sales/PickingList'
import Multipackage from './pages/Sales/Multipackage'
import Multivende from './pages/Settings/Multivende'
import Couriers from './pages/Shippings/Couriers/Couriers'
import Traceability from './pages/Shippings/Traceability'
import Settings from './pages/Shippings/Couriers/Settings'
import Incidents from './pages/Incidents'
// import Modal from './components/shared/Modal/Modal'
import Permissions from './pages/Settings/Permissions/Permissions'
import User from './pages/Settings/Permissions/User'
import Distribution from './pages/Distribution/Distribution'
import MasiveOrderCreation from './pages/Sales/MasiveOrderCreation'
import PackageDetail from './pages/Distribution/PackageDetail'
import ManifestDetail from './pages/Distribution/ManifestDetail'
import ManifestDetails from './pages/Manifest/ManifestDetails'
import SaleDetail from './pages/Sales/SaleDetail'
import NotificationWrapper from './contexts/NotificationContext'
import StoreDetail from './pages/Distribution/StoreDetail'
import GoogleAnalytics from './pages/GoogleAnalytics'
import Refunds from './pages/Refunds/Refunds'
import RefundDetail from './pages/Refunds/RefundDetail'
import CreateRefund from './pages/Refunds/CreateRefund'
import AbandonedCarts from './pages/AbandonedCarts/AbandonedCarts'
import AbandonedCartDetails from './pages/AbandonedCarts/AbandonedCartDetails'
import ScanOrders from './pages/ScanOrders/ScanOrders'

const App = () => {
  const { auth } = useContext(AuthContext)
  // const [showAlert, setShowAlert] = useState(!localStorage.getItem('update31-05'))

  // const handleCloseAlert = () => {
  //   setShowAlert(false)
  //   localStorage.setItem('update31-05', 'showed')
  // }

  return (
    <Router>
      <Interceptors>
        <GlobalStyles />
        <NotificationWrapper>
          {auth ? (
            <div className="h-full w-full bg-general-background flex font-lexend text-ultra-dark-grey">
              <NotificationCenter />
              <Sidebar />
              <div className="flex w-full h-screen overflow-y-auto">
                <div className="main-page">
                  <RouterGuards>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <Routes>
                        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
                        <Route exact path={ROUTES.SALES} element={<Sales />} />
                        <Route path={ROUTES.SALE} element={<SaleDetail />} />
                        <Route path={ROUTES.PACKING} element={<PackingList />} />
                        <Route path={ROUTES.PICKING} element={<PickingList />} />
                        <Route path={ROUTES.MULTIPACKAGE} element={<Multipackage />} />
                        <Route path={ROUTES.COURIERS} element={<Couriers />} />
                        <Route path={ROUTES.COURIER_SETTINGS} element={<Settings />} />
                        <Route path={ROUTES.TRACEABILITY} element={<Traceability />} />
                        <Route exact path={ROUTES.BILLING} element={<Billing />} />
                        <Route exact path={ROUTES.WAREHOUSES} element={<Warehouses />} />
                        <Route path={ROUTES.WAREHOUSE} element={<WarehousePage />} />
                        <Route path={ROUTES.PROFILE} element={<UserProfile />} />
                        <Route path={ROUTES.FAQ} element={<FAQs />} />
                        <Route path={ROUTES.CROSSAPPROVAL} element={<CrossApproval />} />
                        <Route path={ROUTES.MANIFEST} element={<Manifest />} />
                        <Route path={ROUTES.CREATE_MANIFEST} element={<ManifestDetails />} />
                        <Route path={ROUTES.ORDERCREATION} element={<SalesOrderCreation />} />
                        <Route
                          path={ROUTES.MASIVE_ORDER_CREATION}
                          element={<MasiveOrderCreation />}
                        />
                        <Route path={ROUTES.PRICING} element={<Pricing />} />
                        <Route exact path="/" element={<Sales />} />
                        <Route path={ROUTES.PERSONALIZATION} element={<Personalization />} />
                        <Route path={ROUTES.PICKUP_APP} element={<PickUpApp />} />
                        <Route path={ROUTES.MULTIVENDE} element={<Multivende />} />
                        <Route exact path={ROUTES.TICKETS} element={<Tickets />} />
                        <Route exact path={ROUTES.NEW_TICKET} element={<NewTicket />} />
                        <Route exact path={ROUTES.TICKET} element={<ShowTicket />} />
                        <Route exact path={ROUTES.RULES} element={<Rules />} />
                        <Route exact path={ROUTES.NEW_RULE} element={<NewRule />} />
                        <Route exact path={ROUTES.RULE} element={<Rule />} />
                        <Route exact path={ROUTES.INCIDENTS} element={<Incidents />} />
                        <Route exact path={ROUTES.PERMISSIONS} element={<Permissions />} />
                        <Route path={ROUTES.PERMISSIONS_USER} element={<User />} />
                        <Route exact path={ROUTES.DISTRIBUTION} element={<Distribution />} />
                        <Route path={ROUTES.DISTRIBUTION_PACKAGE} element={<PackageDetail />} />
                        <Route path={ROUTES.DISTRIBUTION_MANIFEST} element={<ManifestDetail />} />
                        <Route path={ROUTES.DISTRIBUTION_STORES} element={<StoreDetail />} />
                        <Route path={ROUTES.GOOGLE_ANALYTICS} element={<GoogleAnalytics />} />
                        <Route path={ROUTES.REFUNDS} element={<Refunds />} />
                        <Route path={ROUTES.REFUND} element={<RefundDetail />} />
                        <Route path={ROUTES.CREATE_REFUND} element={<CreateRefund /> } />
                        <Route exact path={ROUTES.ABANDONED_CARTS} element={<AbandonedCarts />} />
                        <Route path={ROUTES.ABANDONED_CART} element={<AbandonedCartDetails />} />
                        <Route path={ROUTES.SCAN} element={<ScanOrders />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </QueryParamProvider>
                  </RouterGuards>
                </div>
              </div>
              {/* <Modal show={showAlert} handleClose={handleCloseAlert}>
                <div className="p-4 max-w-2xl">
                  <div className="font-semibold mb-4">Aviso actualización 8:00 - 25/06/2024</div>
                  <div> */}
                    {/* <span role="img" aria-label="waving hand">¡Hola!</span>
                    <br /><br />
                    <span>
                      Les informamos que el servicio de <strong>Blue Express</strong> está
                      presentando intermitencias. Apenas tengamos más actualizaciones,
                      les iremos informando.
                    </span>
                    <br /><br />
                    <span>
                    Recuerden que ante cualquier duda que tengan, nuestro equipo está atento para
                    poder apoyarlos en lo que necesiten.
                    </span>
                    <br /><br />
                    <span>
                      ¡Mucho éxito con lo que queda de Cyber!
                    </span> */}
                    {/* <ul className="list-disc m-4 text-sm max-w-[400px]">
                      <li className='pb-3'>Los manifiestos ahora pueden cargar órdenes de forma
                        manual en lugar de solo automática.</li>
                      <li className='pb-3'>Se habilitaron webhooks para la creción de órdenes y
                        cambios de estado (solicitar a equipo Pinflag).</li>
                    </ul>
                  </div>
                  <br />
                  <div>Equipo Pinflag</div>
                </div>
                <div className="flex">
                  <button
                    onClick={handleCloseAlert}
                    type="button"
                    className="bg-normal-pinflag text-white py-2 px-10 my-2 mx-auto rounded text-sm"
                  >
                    Listo
                  </button>
                </div>
              </Modal> */}
            </div>
          ) : (
            <Routes>
              <Route path={ROUTES.RECOVERPASS} element={<RecoverPass />} />
              <Route path={ROUTES.RESTOREPASS} element={<RestorePass />} />
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </NotificationWrapper>
      </Interceptors>
    </Router>
  )
}

export default App
